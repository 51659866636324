import React, { useState, useEffect, useCallback } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { debounce } from 'lodash';

export default function DailymedSearchPage() {

  const [productOptions, setProductOptions] = useState([]);
  const [productInputValue, setProductInputValue] = useState('');
  const [productValue, setProductValue] = useState();
  const [loading, setLoading] = useState(false);
  const [dailymedOptions, setDailymedOptions] = useState([]);

  const getProductOptionsAsync = (query) => {
    return new Promise((resolve) => {
      resolve(
        fetch("http://192.168.1.20:8000/products/?q=" + query, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          }
        })
        .then(function (response) {
          return response.json();
        })
        .then(function (options) {
          return options;
        })
      )
    });
  };

  const getProductOptionsDelayed = useCallback(
    debounce((text, callback) => {
      getProductOptionsAsync(text).then(callback);
    }, 200),
    []
  );

  useEffect(() => {
    setProductOptions([]);
    if (productInputValue.length >= 2) {
      setLoading(true);
      getProductOptionsDelayed(productInputValue, (filteredOptions) => {
        setProductOptions(filteredOptions);
        setLoading(false);
      });
    }
  }, [productInputValue, getProductOptionsDelayed]);

  const getDailymedOptionsAsync = (query) => {
    return new Promise((resolve) => {
      resolve(
        fetch("http://192.168.1.20:8000/dailymed_rxnorms/?rxcui=" + query, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          }
        })
        .then(function (response) {
          return response.json();
        })
        .then(function (options) {
          return options;
        })
      )
    });
  };

  const getDailymedOptionsDelayed = useCallback(
    debounce((text, callback) => {
      getDailymedOptionsAsync(text).then(callback);
    }, 200),
    []
  );

  useEffect(() => {
    setDailymedOptions([]);
    if (productValue && productValue.rxcui) {
      setLoading(true);
      getDailymedOptionsDelayed(productValue.rxcui, (filteredOptions) => {
        setDailymedOptions(filteredOptions);
        console.log('dailymed options', filteredOptions);
        setLoading(false);
      });
    }
  }, [productValue, getDailymedOptionsDelayed]);

  return (
    <Container>
      <Typography variant="h4">DailyMed Product Search</Typography>
      <Autocomplete
      disablePortal
      id="clinical-product-search-demo"
      options={productOptions}
      getOptionLabel={ option => option.name }
      filterOptions={(x) => x}
      onInputChange={(e, newInputValue) => setProductInputValue(newInputValue)}
      onChange={(e, newValue) => {
        setProductOptions([]);
        setProductValue(newValue);
      }}
      clearOnBlur={false}
      loading={loading}
      renderInput={(params) => <TextField {...params} label="Product" />}
      />
      
      {dailymedOptions.length > 0 && (
      <TableContainer sx={{ marginTop: 2 }} component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">RXCUI</TableCell>
              <TableCell align="right">TTY</TableCell>
              <TableCell align="right">DailyMed Link</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dailymedOptions.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.rxstr}
                </TableCell>
                <TableCell align="right">{row.rxcui}</TableCell>
                <TableCell align="right">{row.rxtty}</TableCell>
                <TableCell align="right"><a href={"https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=" + row.setid} target="_blank">{row.setid}</a></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      )}
    </Container>
  );
}

import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';


const done = [
    'NLM RxNorm',
    'FDA NDC Directory',
    'FDA Excluded Products',
    'FDA Unfinished Products',
    'FDA Orange Book',
    'FDA Purple Book',
    'Medicaid Utilization',
    'CMS NADAC',
    'CMS ASP',
    'DailyMed Mapping Files',
];

const todo = [
    'DailyMed',
    'DailyMed Indexing and REMS',
    'CMS Drug Spending',
    'Drugs@FDA',
    'Outsourcing Facilities',
    'RxTerms',
    'Drug Establishments',
    'Wholesale Distributors',
    'FDA Compounded Drugs',
    'Remittance Advice Remarks',
    'FAERS Adverse Event Reports',
    'Approved REMS'
];

export default function HeroSection() {

  return (
    <Grid 
        container
    >
        <Grid
            md={6}
            sx={{
                bgcolor:'white',
                height: 'calc(100vh - 64px)',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Box
                m={3}
                sx={{maxWidth: '300px'}}
            >
                <Typography
                    variant="h4"
                    gutterBottom
                >
                Open drug data <br/>made easy.
                </Typography>
                <Typography                        
                    mb={3}
                >
                A developer-first medication data platform built by pharmacists.
                </Typography>
                <Button
                    variant='contained'
                    color='secondary'
                    component={RouterLink}
                    to='/demo'
                >
                    View demo
                </Button>
            </Box>
        </Grid>

        <Grid
            md={6}
            xs={12}
            sx={{
                bgcolor:'primary.light',
                minHeight: 'calc(100vh - 64px)',
                height: '100%',
                padding: 5,
                paddingTop: '200px',
            }}
        >
            <Grid container spacing={3}>
                <Grid
                    item
                    xs={12}
                    md={6}
                >
                    <Typography variant='h5'>Done</Typography>
                {done.map((item) => (
                    <Paper
                        square
                        sx={{
                            marginBottom: 0.5,
                            pointerEvents: 'none'
        
                        }}
                        >
                        <Checkbox
                            defaultChecked
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                            />
                            {item}
                    </Paper>
                ))}
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                >
                    <Typography variant='h5'>Todo</Typography>
                {todo.map((item) => (
                    <Paper
                        square
                        sx={{
                            marginBottom: 0.5,
                            pointerEvents: 'none'

                        }}
                    >
                        <Checkbox                    
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                            />
                            {item}
                    </Paper>
                ))}
                </Grid>
            </Grid>
        </Grid>
        <Grid container sx={{backgroundColor: 'black', color: 'white', minHeight: 'calc(50vh)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
}}>
            <Grid>
                <Typography variant='h5'>Watch us grow.</Typography>
                <Link
            href="https://sagerx.substack.com/?r=ll9x&s=w&utm_campaign=pub&utm_medium=web"
            target="_blank"
          >
            Sign up for our newsletter
          </Link>

            </Grid>
        </Grid>
    </Grid>
  );
}

import React, { useState, useEffect, useCallback } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { debounce } from 'lodash';

export default function IngredientSearchPage() {

  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);

  const getOptionsAsync = (query) => {
    return new Promise((resolve) => {
      resolve(
        fetch("http://192.168.1.20:8000/ingredients/?q=" + query, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          }
        })
        .then(function (response) {
          return response.json();
        })
        .then(function (options) {
          return options;
        })
      )
    });
  };

  const getOptionsDelayed = useCallback(
    debounce((text, callback) => {
      getOptionsAsync(text).then(callback);
    }, 200),
    []
  );

  useEffect(() => {
    setOptions([]);
    if (inputValue.length >= 2 && (!value || value && value.name != inputValue)) {
      setLoading(true);
      getOptionsDelayed(inputValue, (filteredOptions) => {
        setOptions(filteredOptions);
        setLoading(false);
      });
    }
  }, [inputValue, getOptionsDelayed]);

  return (
    <Container>
      <Typography variant="h4">Ingredient Search</Typography>
      <Autocomplete
      disablePortal
      id="ingredient-search-demo"
      options={options}
      getOptionLabel={ option => option.name }
      filterOptions={(x) => x}
      onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
      onChange={(e, newValue) => {
        setOptions([]);
        setValue(newValue);
      }}
      clearOnBlur={false}
      loading={loading}
      renderInput={(params) => <TextField {...params} label="Ingredient" />}
      />
      
      {value &&
        <Card sx={{ maxWidth: 275, marginTop: 5 }}>
          <CardContent>
            <Typography variant="h5" component="div">
              {value.name}
            </Typography>
            <Typography variant="body2">
              RXCUI: {value.rxcui}
              <br />
              TTY: {value.tty}
             </Typography>
          </CardContent>
        </Card>    
      }
    </Container>
  );
}

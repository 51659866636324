import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import fda from './assets/images/fda.svg';
import fdacolor from './assets/images/fdacolor.svg';

function createData(name, calories, fat, carbs) {
    return { name, calories, fat, carbs };
  }
  
  const rows = [
    createData('FDA NDC Directory', 'tab-separated value text files or Excel', 'daily', '1234-5678-10'),
    createData('CMS NADAC', 'comma-separated value text file', 'weekly on Wednesdays', '12345678911'),
    createData('NLM RxNorm', 'pipe-delimited text files in Rich Release Format (RRF)', 'incremental weekly / full monthly', '12345678911'),
  ];

export default function PlantCardsSection() {

    return (
        <Grid
            container
            sx={{
                height: '100vh'
            }}
        >
            <Grid
                md={6}
            >
                <TableContainer
                    component={Paper}>
                    <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Data Source</TableCell>
                            <TableCell align="right">Format</TableCell>
                            <TableCell align="right">Update Frequency</TableCell>
                            <TableCell align="right">NDC Format</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">{row.name}</TableCell>
                                <TableCell align="right">{row.calories}</TableCell>
                                <TableCell align="right">{row.fat}</TableCell>
                                <TableCell align="right">{row.carbs}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Grid
                md={6}
            >
                <Typography 
                    variant="h4"
                >
                    Open does not equal easy.
                </Typography>
                <Typography
                    mt={2}
                    mb={2}
                >
                    Even though this data is publicly available, it comes in different formats and is updated at different frequencies. Also, there are differences in the way things like NDC (or other “should-be” standards) are formatted between different data sources, which means making connections between different data can be difficult.
                </Typography>
            </Grid>
        </Grid>
    );
}

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Routes, Route, Link } from "react-router-dom";
import './App.css';
import Home from './Home';
import NavBar from './NavBar';
import Demo from './Demo';
import Scanner from './Scanner';
import SupplyChainMap from './SupplyChainMap';
import IngredientSearch from './IngredientSearch';
import DailymedSearch from './DailymedSearch';
import NadacPricing from './NadacPricing';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#4caf50',
    },
    secondary: {
      main: '#ce93d8',
    },
    error: {
      main: '#ff5252',
    },
    warning: {
      main: '#ff9100',
    },
    info: {
      main: '#2979ff',
    },
    success: {
      main: '#4caf50',
    },
  },
  typography: {
    fontFamily: 'Roboto Slab',
    button: {
      fontFamily: 'Roboto Slab',
    },
    overline: {
      fontFamily: 'Roboto Slab',
    },
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <NavBar />        
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/scanner" element={<Scanner />} />
          <Route path="/supply-chain-map" element={<SupplyChainMap />} />
          <Route path="/ingredient-search" element={<IngredientSearch />} />
          <Route path="/dailymed-product-search" element={<DailymedSearch />} />
          <Route path="/nadac-pricing" element={<NadacPricing />} />
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;

import * as React from 'react';
import Box from '@mui/material/Box';
import Hero from './Hero';
import PlantCards from './PlantCards';
import Plant from './Plant';
import Customers from './Customers';

import squiggles from './assets/images/squiggles.svg'

export default function HomePage() {

  return (
    <Box>
        <Hero />
        {1==0 &&
        <>
        <PlantCards />
        <Plant />
        <Customers />
        </>
        }
    </Box>
  );
}

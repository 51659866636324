import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

export default function DemoPage() {

  return (
    <Container>
      <Grid>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} underline="hover" color="inherit" to="/">
            Home
          </Link>
          <Typography color="text.primary">Demo</Typography>
        </Breadcrumbs>
        <Typography variant="h4">Demo</Typography>
        <p>A demo to showcase what can be built with SageRx.</p>
        
        <Card variant="outlined">
    <CardContent sx={{display: 'flex'}}>
    <Link component={RouterLink} to="/nadac-pricing" sx={{color:'inherit', textDecoration: 'none'}}>
        <Box 
          mr={2}
          sx={{
            backgroundColor: 'primary.light',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            borderRadius: '5px',
          }}
        >
          <AttachMoneyIcon /> 
        </Box>
        </Link>
        <Box>
          <Link component={RouterLink} to="/nadac-pricing">NADAC Pricing</Link> - Input an NDC11, get the current NADAC price along with a historical chart and table of prices.
        </Box>
        </CardContent>
        </Card>
      </Grid>
    </Container>
  );
}

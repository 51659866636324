import React, { useState, useReducer } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import InputAdornment from '@mui/material/InputAdornment';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import SearchIcon from '@mui/icons-material/Search';
import ResetIcon from '@mui/icons-material/Clear';
import CopyIcon from '@mui/icons-material/ContentCopy';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

import { DataGrid } from '@mui/x-data-grid';
import { ResponsiveLine } from '@nivo/line'
import ReactGA from 'react-ga4';

export default function NadacPricingSearch() {
  const [pageSize, setPageSize] = React.useState(10);
  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState(false);
  const [nadacData, setNadacData] = useState([]);
  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      ndc: ''
    }
  );

  const handleInput = e => {
    const name = e.target.name;
    const newValue = e.target.value;
    setFormInput({ [name]: newValue });
    setNadacData([]);
    setNoData(false);
  };

  const handleReset = e => {
    e.preventDefault();
    setFormInput({ndc: ''});
    setNadacData([]);
    setNoData(false);
  }

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    setNoData(false);

    let ndc = formInput.ndc;

    ReactGA.event({
      category: "nadac-pricing",
      action: "submit",
      label: ndc, // optional
    });

    fetch("https://api.sagerx.io/nadac/" + ndc, {
    //fetch("http://192.168.1.20:8000/nadac/" + ndc, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {
        if(response.length == 0)
        {
          setNoData(true);
        }
        setNadacData(response.sort((a,b) => (a.price_start_date > b.price_start_date) ? -1 : ((b.price_start_date > a.price_start_date) ? 1 : 0)));
        console.log(response);
        setLoading(false);
      })
      .catch(error => {
        setNadacData(error);
        console.error(error);
        setLoading(false);
      });
  };

  const formatChartData = (data) => {
    return [
      {
        'id': 'nadac',
        'color': '#61cdbb',
        'data': data.map(d => { return {'x': d.price_start_date, 'y': d.nadac_per_unit}})
      }
    ]
  };

  const MyResponsiveLine = ({data}) => (
    <ResponsiveLine
        data={data}
        colors={{ scheme: 'accent' }}
        curve='catmullRom'
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        xScale={{
          type: 'time',
          format: '%Y-%m-%d',
          useUTC: false,
          precision: 'day',
          }}
          xFormat="time:%Y-%m-%d"
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false
        }}
        yFormat=" >-$.5f"
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        pointLabelYOffset={-12}
        useMesh={true}
        enableGridX={false}
        enableGridY={false}
        enableArea={true}
    />
)

  const columns = [
    {
      field: 'price_start_date',
      headerName: 'Price Start Date',
      type: 'date',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'nadac_per_unit',
      headerName: 'NADAC Per Unit',
      type: 'number',
      minWidth: 80,
      flex: 1,
    },
  {
    field: 'pricing_unit',
    headerName: 'Unit',
    minWidth: 80,
    flex: 1,
  },
{
    field: 'dollar_change',
    headerName: 'Dollar Change',
    type: 'number',
    minWidth: 80,
    flex: 1,
  },
  {
    field: 'percent_change',
    headerName: 'Percent Change',
    type: 'number',
    minWidth: 80,
    flex: 1,
  },
  /*
  {
    field: 'change_type',
    headerName: 'Change Type',
    width: 150,
    
  },
  {
    field: 'price_line',
    headerName: 'Price Line',
    type: 'number',
    width: 150,
  },
    {
      field: 'current_flag',
      headerName: 'Current Flag',
      width: 150,
    },
    {
      field: 'first_price',
      headerName: 'First Price',
      width: 150,
    },
    {
      field: 'ndc',
      headerName: 'NDC',
      width: 150,
    },
    {
      field: 'ndc_description',
      headerName: 'NDC Description',
      width: 150,
    },
    */

  ];

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item lg={8}>
          <Box pt={3} pb={2}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link component={RouterLink} underline="hover" color="inherit" to="/">
                Home
              </Link>
              <Link
                component={RouterLink}
                underline="hover"
                color="inherit"
                to="/demo"
              >
                Demo
              </Link>
              <Typography color="text.primary">
                NADAC Pricing
              </Typography>
            </Breadcrumbs>

            <Typography variant="h4">
              NADAC Pricing
            </Typography>
            <p>The National Average Drug Acquisition Cost (NADAC) represents the national average invoice price derived from retail community pharmacies for drug products based on invoices from wholesalers and manufacturers.</p>
            <p>Original data by <Link href="https://www.medicaid.gov/medicaid/prescription-drugs/pharmacy-pricing/index.html" target="_blank">Medicaid</Link>. Enhanced data, API, and UI by <Link href="/">SageRx</Link>.</p>
            <p><strong>Please enter an NDC in NDC11 format below.</strong></p>
            <form onSubmit={handleSubmit} action=".">
              <TextField
                label="NDC"
                id="ndc-textfield"
                name="ndc"
                value={formInput.ndc}
                onChange={handleInput}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        type="submit"
                        disabled={loading || formInput.ndc == ''}
                      >
                        {loading ? <CircularProgress size='1em' /> : <SearchIcon />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {formInput.ndc != '' &&
                <IconButton
                  onClick={handleReset}
                  sx={{
                    margin: '8px'
                  }}
                >
                  <ResetIcon />
                </IconButton>
              }
            </form>

          </Box>
          <Box sx={{minHeight: '100vh'}}>
            {nadacData.length === 0 && 
              <>
                {noData && 
                  <Typography
                    color='error'
                  >
                    No results found. Please try again.
                  </Typography>
                }
                <p>Don't have an NDC handy? Try one of these:</p>
                <Button onClick={() => { setFormInput({ndc: '00603233721'}); handleSubmit(); }}><CopyIcon fontSize="small" /> 00603233721</Button><br/>
                <Button onClick={() => setFormInput({ndc: '16729028515'})}><CopyIcon fontSize="small" /> 16729028515</Button><br/>
                <Button onClick={() => setFormInput({ndc: '00054008525'})}><CopyIcon fontSize="small" /> 00054008525</Button>
              </>
            }
            {nadacData.length > 0 && 
              <>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h5" component="div">
                      {nadacData.length > 0 && nadacData[0].ndc_description}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                      NDC {nadacData.length > 0 && nadacData[0].ndc}
                    </Typography>
                    <Typography variant="h4">
                      ${nadacData.length > 0 && nadacData[0].nadac_per_unit + ' / ' + nadacData[0].pricing_unit }
                    </Typography>
                    <Box ml={2.5}>
                      as of {nadacData.length > 0 && nadacData[0].price_start_date}
                    </Box>
                    <Box mt={2}>
                      {nadacData.length > 1 && (nadacData[0].dollar_change<0?"":"+") + nadacData[0].dollar_change} ({nadacData.length > 1 && nadacData[0].percent_change}%) since {nadacData.length > 1 && nadacData[1].price_start_date}
                    </Box>
                    <Box sx={{ height: 150, width: '100%' }}>
                      <MyResponsiveLine data={formatChartData(nadacData)} />
                    </Box>
                  </CardContent>
                </Card>
                <Box mt={2} mb={2} sx={{ height: 500, width: '100%' }}>
                  <DataGrid
                    rows={nadacData}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}            
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    pagination
                  />
                </Box>
              </>
            }
          </Box>
        </Grid>
        <Grid item xs={12}>
        </Grid>
      </Grid>
    </Container>
  );
}